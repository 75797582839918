/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import './assets/styles/variables.scss';

@mixin font($font-size: 14, $font-weight: 400, $font-face: 'Montserrat') {
  font-size: $font-size + px;
  font-weight: $font-weight;
  font-family: $font-face, sans-serif;
}

@function color($color-name, $color-modifier: 'base') {
  @return map-get(map-get($colors, $color-name), $color-modifier);
}

@include mat.core();

$my-app-primary: mat.define-palette(mat.$purple-palette, 800);
$my-app-accent: mat.define-palette(mat.$orange-palette, 400, 900, A100);
$my-app-warn: mat.define-palette(mat.$red-palette);
$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
  )
);
@include mat.all-component-themes($my-app-theme);

// Colours
:root {
  --primary-colour: #3a3372;
  --primary-colour-lightest: #e3e2ee;
}

body {
  position: relative !important;
}

/* Resets */
ul {
  list-style: none;
  padding: 0;
}
button,
input {
  border: none;
  outline: none;
}
button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

/* Angular Material Adjusts */
.mat-expansion-panel-header-title {
  font-weight: 600;
  color: color(grey);
}
@media only screen and (max-width: 478px) {
  .mat-expansion-panel-header-title {
    font-size: 12px;
  }
}
.mat-grid-tile .mat-figure {
  justify-content: unset !important;
  padding: 20px !important;
}

mat-radio-button.subscriptionRadioButton {
  .mat-radio-label {
    .mat-radio-container {
      background: white;
      border-radius: 20px;
    }
  }
}

/* Generic Classes */
.inner-content {
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'MontSerrat';
  padding: 0px 30px;
}

.studentCalendar {
  .cal-month-view .cal-day-badge {
    display: none;
  }

  .cal-month-view .cal-cell-top {
    min-height: 0px !important;
  }

  .cal-month-view .cal-day-cell {
    min-height: unset !important;
    position: relative !important;
  }

  .cal-month-view .cal-day-cell.cal-today .cal-day-number {
    font-size: 1.2rem;
  }

  .cal-month-view .cal-header .cal-cell {
    font-size: 12px;
  }

  .cal-month-view .cal-events {
    top: 65%;
    position: absolute;
  }
}

.custom-modalbox {
  mat-dialog-container {
    padding: 0px 0px 24px 0px !important;
  }
}

.theme-modalBox {
  mat-dialog-container {
    padding: 0px 0px 24px 0px !important;
    border-radius: 15px !important;
    border: solid 1px color(primary) !important;
  }
}

.graph-modalBox {
  mat-dialog-container {
    padding: 0px !important;
    border-radius: 15px !important;
    border: solid 1px color(primary) !important;
  }
}

.courseCard {
  mat-card {
    padding: 20px 0px 0px 0px;
  }
}

.client-list-modalBox {
  mat-dialog-container {
    padding: 0px 0px 0px 0px !important;
    border-radius: 15px !important;
    border: solid 3px color(primary) !important;
  }
}

mat-accordion.dropdown {
  background-color: rgba(150, 150, 150, 0.3);
}

mat-accordion.lessonDropdown {
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 0px 0px 0px;
  }

  .mat-expansion-panel {
    background-color: #f2f3f5;

    mat-expansion-panel-header {
      border-bottom: 2px solid color(primary);
      padding-bottom: 26px !important;
    }

    mat-expansion-panel-header:hover {
      background-color: #f2f3f5 !important;
    }

    .mat-expansion-panel-body {
      padding: 0px !important;
    }
  }
}

.errorSnackbar {
  text-align: center;
  line-break: auto;
}

.modal-border {
  mat-dialog-container {
    padding: 0px !important;
  }
}

.beforeYouStart {
  mat-dialog-container {
    border-radius: 30px !important;
    box-shadow: none !important;
    background: none !important;
  }
}

.public-profile-preview {
  mat-dialog-container {
    padding: 50px !important;
  }
}

.cdk-overlay-pane {
  max-width: 90vw !important;
}

.instructionTooltip {
  background-color: color(primary) !important;
  opacity: 1 !important;
}

@media only screen and (max-width: 960px) {
  div#App .hide-sm {
    display: none;
  }
}
@media only screen and (min-width: 961px) {
  div#App .hide-lg {
    display: none;
  }
}

.search-input {
  .icon {
    padding: 10px;
    background: color(primary);
    color: white;
    min-width: 50px;
    text-align: center;
  }
  input,
  select {
    border: 0.5px solid color(primary);
    color: color(primary);
    padding: 7px 5px;
    &::placeholder {
      color: rgba(color(primary), 0.5);
    }
  }
}

/* Auth0 Popup Adjusts */
.auth0-lock.auth0-lock .auth0-lock-header-avatar,
.auth0-lock.auth0-lock .auth0-lock-close-button {
  z-index: 100000 !important;
}
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  height: 185px !important;
}
.auth0-lock.auth0-lock.auth0-lock-opened {
  top: calc(25vh - 200px);
}
.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-center {
  overflow: hidden !important;
}

.auth0-lock.auth0-lock .auth0-lock-widget {
  width: 400px !important;
}

.auth0-lock.auth0-lock .auth0-lock-header-logo {
  width: 400px !important;
}

@-moz-document url-prefix() {
  .auth0-lock.auth0-lock.auth0-lock-opened {
    top: initial;

    form.auth0-lock-widget {
      position: absolute;
      top: calc(50vh - 250px);
      left: calc(50vw - 150px);
    }
  }
}

.mat-tooltip {
  font-size: 1.2rem !important;
  padding: 10px !important;
}
.mat-tooltip-hide {
  display: none !important;
}

.mat-mdc-dialog-surface {
  overflow-x: hidden !important;
}

.toggle-b2c-signup {
  // color override for b2c signup button
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: white;
  }
  .mat-checkbox-frame {
    border-color: white;
  }
}

body.no-scroll {
  overflow-y: hidden !important; // used for stopping scroll when mobile menu is open
}

.snackbar-multiline {
  white-space: pre-line;
}
